<template>
  <div>
    <h3>Create Banner</h3>
    <div class="mt-2" v-if="this.$route.query.campaignId">
      <v-alert dense text type="success">
        Yaaaay! Off to the next step, add creatives to your campaign 🚀
      </v-alert>
    </div>

    <add-new-banner
      :banner="dsp_creative"
      :rules="dsp_campaign_rules"
      :all_campaigns="__allcampaigns"
      :restricted_categories="static_data"
      :fetched_campaign="dsp_campaign?.campaign"
      @emitBannerBodyCreate="createBanner($event)"
      @emitBannerBodyEdit="EditBanner($event)"
      @getSelectedCampaign="getSelectedCampaign($event)"
      class="mt-5"
    />
  </div>
</template>

<script>
import AddNewBanner from "../../../components/Programmatic/AddNewBanner.vue";
import { mapState, mapGetters } from "vuex";

export default {
  data: () => ({
    items: [
      {
        text: "Creatives",
        disabled: false,
        href: "#/tradedesk/creative",
      },
      {
        text: "Add / Edit Banner",
        disabled: true,
        href: "#",
      },
    ],
    restricted_categories: [],
    campaign: null,
    error: null,
  }),
  components: {
    AddNewBanner,
  },
  methods: {
    async getStaticDspData() {
      try {
        await this.$store.dispatch("dsp/getStaticDspData");
      } catch (error) {
        this.error = error;
      }
    },
    //Create new Banner
    async createBanner(banner_body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/createDspBanner", banner_body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Banner created successfully!",
          group: "success",
        });

        this.$router.push(this.__channel?.path);
      } catch (error) {
        this.$notify({
          title: error.message,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    //Update banner
    async EditBanner(banner_body) {
      try {
        let body = {
          banner_id: this.$route.params.id,
          banner_body: banner_body,
        };
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateDspBanner", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Banner updated successfully!",
          group: "success",
        });

        this.$router.push(this.__channel?.path);
      } catch (error) {
        this.$notify({
          title:
            "Sorry, there was a problem updating the banner. Please try again later",
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },

    //Get all campaigns
    async getCampaigns() {
      //Campaigns
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getAllDspCampaigns");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },

    //get Selected Campaign
    async getSelectedCampaign(campaignID) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getOneDspCampaign", campaignID);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    //get single creative on edit
    async singleCreative() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/getSingleCreative",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
  watch: {
    static_data() {
      this.restricted_categories = this.static_data.restricted_categories;
    },

    "$route.query.campaignId": {
      handler: function (value) {
        if (value) {
          this.getSelectedCampaign(value);
          this.getCampaigns();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getCampaigns();
    this.getStaticDspData();
  },
  async mounted() {
    //Get single creative on edit
    if (this.$route.name === "edit-programmatic-banner") {
      this.singleCreative();
    }

    //Campaign Rules
    try {
      this.$store.dispatch("dsp/getAllCampaignRules");
    } catch (error) {
      this.error = error;
    }
  },
  computed: {
    ...mapState({
      dsp_campaigns: (state) => state.dsp.dsp_campaigns,
      dsp_campaign: (state) => state.dsp.dsp_campaign,
      dsp_campaign_rules: (state) => state.dsp.dsp_campaign_rules,
      dsp_creative: (state) => state.dsp.dsp_creative,
      static_data: (state) => state.dsp.static_dsp_data.restricted_categories,
    }),
    ...mapGetters({
      superAdmin: "superAdmin",
      getCampaignsByChannel: "dsp/getCampaignsByChannel",
    }),
    __allcampaigns() {
      return this.getCampaignsByChannel(this.__channel?.app);
    },
    //determine if its programmatic or app campaigns
    __channel() {
      const currentRoute = this.$route.name;
      let channel;

      if (
        currentRoute === "add-programmatic-banner" ||
        this.$route.name === "edit-programmatic-banner"
      ) {
        channel = {
          app: "programmatic",
          path: "/tradedesk/creatives",
        };
      } else if (
        currentRoute === "add-opera-banner" ||
        this.$route.name === "edit-programmatic-banner"
      ) {
        channel = {
          app: "opera",
          path: "/opera/creatives",
        };
      } else if (
        currentRoute === "add-phoenix-banner" ||
        this.$route.name === "edit-programmatic-banner"
      ) {
        channel = {
          app: "phoenix",
          path: "/phoenix/creatives",
        };
      } else if (
        currentRoute === "add-transsion-banner" ||
        this.$route.name === "edit-programmatic-banner"
      ) {
        channel = {
          app: "transsion",
          path: "/transsion/creatives",
        };
      } else if (
        currentRoute === "add-truecaller-banner" ||
        this.$route.name === "edit-programmatic-banner"
      ) {
        channel = {
          app: "truecaller",
          path: "/truecaller/creatives",
        };
      } else if (
        currentRoute === "add-ctv-banner" ||
        this.$route.name === "edit-programmatic-banner"
      ) {
        channel = {
          app: "ctv",
          path: "/ctv/creatives",
        };
      }
      else if (
        currentRoute === "add-gaming-banner" ||
        this.$route.name === "edit-programmatic-banner"
      ) {
        channel = {
          app: "gaming",
          path: "/gaming/creatives",
        };
      }
      return channel;
    },
    _breadCrumbs() {
      return [
        {
          text: "Creatives",
          disabled: false,
          href: `#/${this.__channel?.path}`,
        },
        {
          text: "Add / Edit Banner",
          disabled: true,
          href: "#",
        },
      ];
    },
    // dsp_campaigns() {
    //   return this.$store.state.dsp.dsp_dropdown_campaigns;
    // },
    // dsp_campaign() {
    //   return this.$store.state.dsp.dsp_campaign;
    // },
    // dsp_campaign_rules() {
    //   return this.$store.state.dsp.dsp_campaign_rules;
    // },
    // dsp_creative() {
    //   return this.$store.state.dsp.dsp_creative;
    // },
    // static_data() {
    //   return this.$store.state.dsp.static_dsp_data.restricted_categories;
    // },
  },
  beforeDestroy() {
    const payload = {};
    payload.dsp_creative = null;
    this.$store.commit("dsp/setDspCreative", payload);
  },
};
</script>
