<template>
  <div>
    <v-row dense class="flex-column">
      <v-col v-if="superAdmin">
        <h5 class="mb-1 warning--text">Rules</h5>

        <v-select v-model="selected_banner_targeting_options" :items="rules" item-text="name" label="Select Rules"
          multiple outlined dense hide-details="auto" single-line>
          <template v-slot:selection="{ item, index }">
            <v-chip small v-if="index < 2">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text text-caption">
              (+{{ selected_banner_targeting_options.length - 2 }} others)
            </span>
          </template>
        </v-select>
        <v-divider class="my-5" />
      </v-col>

      <v-col>
        <h5 class="mb-2">Banner Budget</h5>
        <v-row dense>
          <v-col v-if="false">
            <v-text-field type="number" outlined dense v-model="banner_budget" label="$30.00"
              hide-details="auto"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field type="number" outlined dense v-model="daily_budget" label="$ Daily Budget"
              hide-details="auto"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field type="number" outlined dense v-model="total_budget" label="$ Total Budget"
              hide-details="auto"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!campaign?.campaign_attribute?.campaign_objective">
        <h5 class="mb-2">Frequency Capping</h5>
        <v-row>

          <v-col cols="12">
            <v-text-field outlined dense label="Frequency Spec" v-model="frequency_spec"
              hide-details="auto"></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field type="number" outlined dense v-model="frequency_count" label="Frequency Count"
              hide-details="auto" placeholder="0" persistent-placeholder></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field type="number" outlined dense v-model="frequency_expire_after" label="Expires After(hours)"
              hide-details="auto" placeholder="0" persistent-placeholder></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    rules: {
      Array,
    },
    campaign: {
      Object,
    },
    banner: {
      Object,
    },
  },
  data: () => ({
    time_formats: ["minutes", "hours"],
    time_format: "minutes",
    frequency_spec: "device.ip",
    frequency_expire_after: null,
    frequency_count: null,
    selected_banner_targeting_options: [],
    banner_budget: null,
    daily_budget: null,
    total_budget: null,
  }),
  watch: {
    selected_banner_targeting_options() {
      this.bannerTargetingOptions();
    },
    banner_budget() {
      this.bannerBudget();
    },
    daily_budget() {
      this.dailyBudget();
    },
    total_budget() {
      this.totalBudget();
    },
    frequency_spec() {
      this.frequencySpec();
    },
    frequency_expire_after() {
      this.frequencyExpire();
    },
    frequency_count() {
      this.frequencyCount();
    },
    campaign() {
      const selected_targeting_ids = this.campaign.rtb_standards
        ? this.campaign.rtb_standards.map((rule) => rule.id)
        : [];
      this.selected_banner_targeting_options = this.rules.filter((rule) =>
        selected_targeting_ids.includes(rule.id)
      );
    },
    banner() {
      if (this.$route.name == "edit-programmatic-banner" && this.banner) {
        this.daily_budget = this.banner.daily_budget
          ? parseInt(this.banner.daily_budget).toFixed(2)
          : null;
        this.frequency_count = this.banner.frequency_count;
        this.frequency_expire_after = this.convertFrequencyToHours(this.banner.frequency_expire);
        this.frequency_spec = this.banner.frequency_spec;
        this.total_budget = this.banner.total_budget
          ? parseInt(this.banner.total_budget).toFixed(2)
          : null;
        this.banner_budget = this.banner.total_cost
          ? parseInt(this.banner.total_cost).toFixed(2)
          : null;
      }
    },
  },
  mounted() {
    this.frequencySpec();
    this.bannerTargetingOptions();
    this.bannerBudget();
    this.dailyBudget();
    this.totalBudget();
    this.frequencyExpire();
    this.frequencyCount();
  },
  methods: {
    convertFrequencyToHours(frequency) {
      //convert seconds to hours
      return frequency / 3600;
    },

    //convert hours to seconds
    convertHoursToSeconds(hours) {
      //check if hours is not null
      if (!hours) {
        return 0;
      }
      return hours * 3600;
    },
    frequencySpec() {
      this.$emit("emitFrequencySpec", this.frequency_spec);
    },
    bannerTargetingOptions() {
      const rule_ids = this.selected_banner_targeting_options.map(
        (rule) => rule.id
      );
      this.$emit("emitBannerTargetingOptions", rule_ids);
    },
    bannerBudget() {
      this.$emit("emitBannerBudget", this.banner_budget);
    },
    dailyBudget() {
      this.$emit("emitDailyBudget", this.daily_budget);
    },
    totalBudget() {
      this.$emit("emitTotalBudget", this.total_budget);
    },
    frequencyExpire() {
      this.$emit("emitFrequencyExpireAfter", this.convertHoursToSeconds(this.frequency_expire_after));
    },
    frequencyCount() {
      this.$emit("emitFrequencyCount", this.frequency_count);
    },
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
};
</script>
